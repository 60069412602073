import React, { Suspense, lazy, ReactNode } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import { getCookie } from "typescript-cookie";
import { COOKIE } from "./api";
import Preloader from "./components/preloader";
import PromoPlacementForm from "./pages/classic-plus/promotion/promotion-placement-form";

// Dashboard Pages
const LocationDashboard = lazy(
    () => import("./pages/classic-plus/location/location-dashboard")
);
const AdminDashboard = lazy(
    () => import("./pages/classic-plus/admin/admin-dashboard")
);
const PromotionDashboard = lazy(
    () => import("./pages/classic-plus/promotion/promotion-dashboard")
);
const TravelProductDashboard = lazy(
    () => import("./pages/classic-plus/travel-product/travel-product-dashboard")
);

const PushNotificationDashboard = lazy(
    () =>
        import("./pages/classic-plus/push-notification/notification-dashboard")
);

const BookingImportDashboard = lazy(
    () => import("./pages/classic-plus/booking/import/booking-import-dashboard")
);

const BookingImportDetailPage = lazy(
    () => import("./pages/classic-plus/booking/import/booking-import")
);

const ClassicPlusSignIn = lazy(() => import("./pages/classic-plus/signin"));
const ClassicPlusSignUp = lazy(() => import("./pages/classic-plus/signup"));

const ClassicPlusForgotPassword = lazy(
    () => import("./pages/classic-plus/forgot-password")
);
const ClassicPlusErrorNotFound = lazy(
    () => import("./pages/classic-plus/error-404")
);
const ClassicPlusError500 = lazy(
    () => import("./pages/classic-plus/error-500")
);
const ClassicPlusError503 = lazy(
    () => import("./pages/classic-plus/error-503")
);
const ClassicPlusError505 = lazy(
    () => import("./pages/classic-plus/error-505")
);
const Error403 = lazy(() => import("./pages/classic-plus/error-403"));

// Create forms
const AdminCreateForm = lazy(
    () => import("./pages/classic-plus/admin/forms/admin-create-form")
);
const LocationCreateForm = lazy(
    () => import("./pages/classic-plus/location/forms/location-create-form")
);
const PromoCreateForm = lazy(
    () => import("./pages/classic-plus/promotion/forms/promotion-create-form")
);
const TravelProductCreateForm = lazy(
    () =>
        import(
            "./pages/classic-plus/travel-product/forms/travel-product-create-form"
        )
);
const TravelPackageCreateForm = lazy(
    () =>
        import(
            "./pages/classic-plus/travel-package/forms/travel-package-create-form"
        )
);

const PushNotificationCreateForm = lazy(
    () =>
        import(
            "./pages/classic-plus/push-notification/forms/notification-create-form"
        )
);

// Edit forms
const AdminEditForm = lazy(
    () => import("./pages/classic-plus/admin/forms/admin-edit-form")
);
const LocationManageForm = lazy(
    () => import("./pages/classic-plus/location/forms/location-edit-form")
);

const PromoEditForm = lazy(
    () => import("./pages/classic-plus/promotion/forms/promotion-edit-form")
);

const TravelProductEditForm = lazy(
    () =>
        import(
            "./pages/classic-plus/travel-product/forms/travel-product-edit-form"
        )
);
const TravelPackageEditForm = lazy(
    () =>
        import(
            "./pages/classic-plus/travel-package/forms/travel-package-edit-form"
        )
);

const PushNotificationEditForm = lazy(
    () =>
        import(
            "./pages/classic-plus/push-notification/forms/notification-edit-form"
        )
);

// Location reviews
const LocationReviewPage = lazy(
    () => import("./pages/classic-plus/location/location-review-page")
);

// QR scanning page
const QRScanPage = lazy(() => import("./pages/classic-plus/qrscan/qrscan"));

// Check in reports page
const CheckinReportDashboard = lazy(
    () => import("./pages/classic-plus/checkin-report/checkin-report-dashboard")
);

const CheckinReport = lazy(
    () => import("./pages/classic-plus/checkin-report/checkin-report")
);

// Ad placement page
const PromoPlacementPage = lazy(
    () => import("./pages/classic-plus/promotion/promotion-placement-form")
);

const PromoPinnedDashboard = lazy(
    () => import("./pages/classic-plus/promotion/promotion-pinned")
);

const TravelProductPinnedDashboard = lazy(
    () => import("./pages/classic-plus/travel-product/travel-product-pinned")
);

type RouteProps = {
    children: ReactNode;
};

const ProtectedRoute = ({ children }: RouteProps) => {
    const stokeToken = getCookie(COOKIE.AccessToken);
    const location = useLocation();

    return stokeToken ? (
        <>{children}</>
    ) : (
        <Navigate to="/signin" state={{ ...location }} />
    );
};

const AuthRoute = ({ children }: RouteProps) => {
    const stokeToken = getCookie(COOKIE.AccessToken);
    return stokeToken ? <Navigate to="/" /> : <>{children}</>;
};

// Should show different routes per admin role type
const App: React.FC = () => {
    const adminRole = getCookie(COOKIE.AdminRole);

    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        {/* Dashboard Routes */}
                        {/* TODO: Later add dashboard- / should be either dashboard or statistics */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <TravelProductDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/travel-products"
                            element={
                                <ProtectedRoute>
                                    <TravelProductDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/travel-products/pinned"
                            element={
                                <ProtectedRoute>
                                    <TravelProductPinnedDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/travel-products/new"
                            element={
                                <ProtectedRoute>
                                    <TravelProductCreateForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/travel-products/:id/manage"
                            element={
                                <ProtectedRoute>
                                    <TravelProductEditForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/travel-products/:id/packages/new"
                            element={
                                <ProtectedRoute>
                                    <TravelPackageCreateForm />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="/travel-products/:productId/packages/:packageId/manage"
                            element={
                                <ProtectedRoute>
                                    <TravelPackageEditForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/locations"
                            element={
                                <ProtectedRoute>
                                    <LocationDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="locations/new"
                            element={
                                <ProtectedRoute>
                                    <LocationCreateForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="locations/:id/manage"
                            element={
                                <ProtectedRoute>
                                    <LocationManageForm />
                                </ProtectedRoute>
                            }
                        />
                        {/* <Route
                            path="locations/:id/reviews"
                            element={
                                <ProtectedRoute>
                                    <LocationReviewPage />
                                </ProtectedRoute>
                            }
                        /> */}
                        {/* <Route
                            path="/admins"
                            element={
                                <ProtectedRoute>
                                    <AdminDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/admins/new"
                            element={
                                <ProtectedRoute>
                                    <AdminCreateForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/admins/:id/manage"
                            element={
                                <ProtectedRoute>
                                    <AdminEditForm />
                                </ProtectedRoute>
                            }
                        /> */}
                        <Route
                            path="/promotions"
                            element={
                                <ProtectedRoute>
                                    <PromotionDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/promotions/new"
                            element={
                                <ProtectedRoute>
                                    <PromoCreateForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/promotions/:id/manage"
                            element={
                                <ProtectedRoute>
                                    <PromoEditForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/promotions/pinned"
                            element={
                                <ProtectedRoute>
                                    <PromoPinnedDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/promotions/placement"
                            element={
                                <ProtectedRoute>
                                    <PromoPlacementForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/push-notifications"
                            element={
                                <ProtectedRoute>
                                    <PushNotificationDashboard />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="/push-notifications/new"
                            element={
                                <ProtectedRoute>
                                    <PushNotificationCreateForm />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="/push-notifications/:id/manage"
                            element={
                                <ProtectedRoute>
                                    <PushNotificationEditForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/booking-imports"
                            element={
                                <ProtectedRoute>
                                    <BookingImportDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/booking-imports/:id"
                            element={
                                <ProtectedRoute>
                                    <BookingImportDetailPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/signin"
                            element={
                                <AuthRoute>
                                    <ClassicPlusSignIn />
                                </AuthRoute>
                            }
                        />
                        {/* <Route
                            path="/checkin"
                            element={
                                <ProtectedRoute>
                                    <QRScanPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/checkin/reports"
                            element={
                                <ProtectedRoute>
                                    <CheckinReportDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/checkin/reports/:id"
                            element={
                                <ProtectedRoute>
                                    <CheckinReport />
                                </ProtectedRoute>
                            }
                        /> */}

                        {/* <Route path="/signup" element={<ClassicPlusSignUp />} /> */}
                        {/* <Route
                            path="/forgot-password"
                            element={<ClassicPlusForgotPassword />}
                        /> */}

                        {/* Error Routes */}
                        <Route path="/500" element={<ClassicPlusError500 />} />
                        <Route path="/503" element={<ClassicPlusError503 />} />
                        <Route path="/505" element={<ClassicPlusError505 />} />
                        <Route path="/403" element={<Error403 />} />

                        {/* 404 Page Route */}
                        <Route
                            path="*"
                            element={<ClassicPlusErrorNotFound />}
                        />
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
