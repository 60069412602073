import { FC } from "react";
import { StyledLogo } from "./style";
import Logo from "../../../../../shared/images/logo/logo.svg";

const AsideLogo: FC = () => {
    return (
        <StyledLogo className="aside-logo" path="/">
            <img src={Logo} alt="stoke app logo" width="75px" />
        </StyledLogo>
    );
};

export default AsideLogo;
