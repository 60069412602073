import {
    User,
    File,
    PieChart,
    Package,
    Box,
    Archive,
    ShoppingBag,
    Globe,
    FileText,
    Bell,
} from "react-feather";
import { IMenu, ISubmenu } from "types";

export const yesTripAsideMenu: ISubmenu[] = [
    {
        id: 9,
        label: "Booking Imports",
        url: "/booking-imports",
        Icon: ShoppingBag,
    },
];

export const stokeAsideMenu: ISubmenu[] = [
    // {
    //     id: 10,
    //     label: "Dashboard",
    //     url: "/",
    //     Icon: FileText,
    // },
    {
        id: 11,
        label: "Travel Products",
        url: "/travel-products",
        Icon: ShoppingBag,
    },
    {
        id: 12,
        label: "Locations",
        url: "/locations",
        Icon: Globe,
    },
    // {
    //     id: 13,
    //     label: "Admins",
    //     url: "/admins",
    //     Icon: User,
    // },
    {
        id: 14,
        label: "Promotions",
        url: "/promotions",
        Icon: File,
    },
    {
        id: 15,
        label: "Push Notifications",
        url: "/push-notifications",
        Icon: Bell,
    },
    // {
    //     id: 15,
    //     label: "Check in Reports",
    //     url: "/checkin/reports",
    //     Icon: File,
    // },
];
