import styled from "@doar/shared";
import React from "react";
import { COOKIE } from "src/api";
import { getCookie } from "typescript-cookie";
import AsideMenu from "./aside";

interface IProps {
    aside?: "minimize";
    sidebarLayout?: 1 | 2;
}

const StyledAside = styled.div`
    .aside-header {
        background-color: #c3f3f3;
    }

    .aside-body {
        background-color: #c3f3f3;
    }
`;

const Layout: React.FC<IProps> = ({ children, aside, sidebarLayout }) => {
    const stokeToken = getCookie(COOKIE.AccessToken);
    return (
        <>
            {stokeToken && (
                <StyledAside>
                    <AsideMenu layout={aside} sidebarLayout={sidebarLayout} />
                </StyledAside>
            )}
            {children}
        </>
    );
};

Layout.defaultProps = {
    sidebarLayout: 1,
};

export default Layout;
