import styled from "@doar/shared";
import { AppHeader, AppNavbar, ForYouBodyContainer } from "./promotion-preview";
import { StyledPreview, StyledPreviewContainer } from "./styles";

interface IProps {
    startIndex: number | null;
    repeatPosition: number | null;
}

const GridContent = styled.div<{ highlight?: boolean }>`
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: ${(props) =>
        props && props.highlight ? "#A147CB" : "#9d9092"};
`;

const MAX_GRID = 10;

const PromotionPlacementPreview: React.FC<IProps> = ({
    startIndex,
    repeatPosition,
}) => {
    const renderForYouGrid = () => {
        const grid = [];
        let i;
        for (i = 0; i < MAX_GRID; i += 1) {
            const hasRule = !!startIndex && !!repeatPosition;

            if (
                (startIndex && startIndex === i + 1) ||
                (startIndex &&
                    repeatPosition &&
                    i + 1 > startIndex &&
                    ((i + 1) % repeatPosition) -
                        (startIndex % repeatPosition) ===
                        0)
            ) {
                grid.push(
                    <GridContent highlight key={i}>
                        {i + 1}{" "}
                    </GridContent>
                );
            } else {
                grid.push(<GridContent key={i}>{i + 1} </GridContent>);
            }
        }
        return grid;
    };

    return (
        <StyledPreviewContainer>
            <StyledPreview>
                <AppNavbar />
                <AppHeader />
                <ForYouBodyContainer>{renderForYouGrid()}</ForYouBodyContainer>
            </StyledPreview>
        </StyledPreviewContainer>
    );
};

export default PromotionPlacementPreview;
