import React from "react";

export const GENERAL_ERROR = "Something went wrong please try again";
export const ID_WRONG_FORMAT_ERROR = "Provided IDs are in wrong format";
export const INVALID_CREDENTIAL_ERROR =
    "Invalid email address/ password, please try again";
export const INVALID_TOKEN_ERROR = "Invalid credentials, please log in again";
export const NO_BOOKING_ID_ERROR =
    "There is no booking associated with this ID";
export const NO_TICKET_ID_ERROR = "There is no ticket associated with this ID";
export const TICKET_ALREADY_CHECKED_IN_ERROR =
    "This ticket has already been checked in";
export const NO_ADDON_ERROR = "There are no add ons for ths package";
