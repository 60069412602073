import { Button } from "@doar/components";
import styled, { Row, Col } from "@doar/shared/styled";
import { prependOnceListener } from "process";

// TODO: pull out preview related styles to a different file

export const StyledPreviewContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
`;

export const StyledPreview = styled.div`
    display: flex;
    flex-direction: column;
    width: 425px;
    height: 100%;
    font-family: Montserrat;
    color: white;
    position: relative;
    background-color: rgba(49, 49, 49, 10);
`;

export const ProductPreviewHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 2rem;

    align-items: flex-start;
    background: linear-gradient(0deg, #423737, transparent, #423737);
    height: 379px;

    flex-direction: column;
    justify-content: space-between;
`;

export const LocationPreviewHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
`;

export const TravelPackageHeader = styled.div`
    padding: 2rem 0 0.5rem;
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 1.5rem;
`;

export const TravelPackageBody = styled.div`
    padding: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const TravelPackageDetailContainer = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
`;

export const TravelPackageDescContainer = styled.div`
    padding: 2rem;
    font-family: Montserrat;
    font-weight: 400;
    color: #ffffff;
    min-height: 20rem;
`;

export const TravelPackageDetailGrid = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const TravelPackageDetailTitle = styled.div`
    font-family: Montserrat;
    color: #ffffff;
    font-weight: 700;
`;

export const TravelPackageActionButtonContainer = styled.div`
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
`;
export const TravelPackageBox = styled.div`
    border: 1px solid;
    background: #3e3e3e;
    color: #ffffff;
    border: none;
`;

export const TravelPackeMoreDetailButton = styled.div`
    border: 1px solid;
    margin-top: 0.75rem;
    background: #908c8c6b;
    color: #ffffff;
    border-color: transparent;
    padding: 0.25rem 0.5rem;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 0.7rem;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: underline;
    display: flex;
    align-items: center;
`;

export const TravelPackageFooter = styled.div`
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
`;

export const TravelPackagePriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
`;

export const TravelPackageDetailImage = styled.div`
    background-image: linear-gradient(
        45deg,
        rgba(151, 93, 224, 0.9),
        rgba(21, 228, 216, 0.9)
    );
    margin-right: 0.75rem;
    width: 5rem;
    padding: 2px;
`;

export const TravelPackageBookNowButton = styled.div`
    background-image: linear-gradient(
        45deg,
        rgba(151, 93, 224, 0.9),
        rgba(21, 228, 216, 0.9)
    );
    padding: 0.25rem 0.5rem;
    font-family: Montserrat;
    font-weight: 400;
`;

export const PreviewHeaderSection = styled.div`
    display: flex;
    width: calc(100% / 3);
`;

export const HeaderButtonContainer = styled.div`
    margin-right: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    background: #15e4d8;
    display: flex;
    border-radius: 50%;
    align-items: center;
`;

export const ReviewStars = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
`;

export const VenueName = styled.div`
    font-size: 1.75rem;
    line-height: 1.5rem;
    padding: 5rem 1.75rem 0 1.75rem;
    min-height: 5.875rem;
    line-height: 1.25;
`;

export const VenueDesc = styled.div`
    padding: 0.5rem 1.75rem 0;
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: rem;
    overflow-wrap: break-word;
`;

export const VenueLocation = styled.div`
    padding-top: 1.25rem;
    font-size: 0.875rem;
    line-height: 1rem;
    padding-left: 1.75rem;
    min-height: 4rem;
`;

export const PreviewFooter = styled.div`
    width: 100%;
    padding-left: 10%;
    margin: 1rem 0;
`;

export const BenefitButton = styled.button`
    width: 90%;
    height: 3.125rem;
    border: 1px solid;
    background: #313131;
    color: #ffffff;

    border-radius: 50px;
    font-family: Montserrat;

    box-shadow: 0 0 6px #15e4d8;
    border-color: transparent;

    letter-spacing: 1px;
`;

export const PreviewRow = styled.div`
    display: flex;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
`;

export const BenefitContainer = styled.div`
    margin-top: 1.25rem;
    width: 90%;
    padding: 1rem;
    background: #3e3e3e;
    margin-left: 5%;
`;

export const BenefitTitle = styled.div`
    font-size: 1rem;
    padding: 0 1rem;
    text-align: center;
    min-height: 1rem;
    overflow-wrap: break-word;
`;

export const BenefitGrid = styled.div`
    grid-template-columns: auto auto;
    display: grid;
    padding-top: 1rem;
    gap: 0 20px;
`;

export const BenefitLabel = styled.div`
    color: #15e4d8;
    align-self: end;
`;

export const BenefitValue = styled.div<{ isRed?: boolean }>`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125;
    min-height: 1.5rem;
    color: #fff;
    margin-bottom: 0.5rem;

    ${(props) => (props.isRed ? `color: red` : null)}
`;

export const ReviewStarContainer = styled.div`
    justify-content: space-between;
    display: flex;
    padding: 0.5rem 1.75rem 1.5rem 1.75rem;
    align-items: center;
`;

export const ReviewContainer = styled.div`
    background-color: #15e4d8;
    margin: 1rem 1rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    min-height: 10rem;

    align-items: left;
    border-bottom: 1px solid #15e4d8;

    h1 {
        color: white;
        display: flex;
        font-family: Montserrat;
        font-size: 1.5rem;
        padding-top: 1rem;
    }

    h4 {
        color: white;
        display: flex;
        font-family: Montserrat;
        font-size: 1rem;
        padding-top: 0.75rem;
    }

    p {
        color: white;
        display: flex;
        font-family: Montserrat;
        font-size: 0.875rem;
        font-weight: 500;
        word-break: break-word;
    }
`;

export const SaveButton = styled.div`
    background-image: linear-gradient(
        45deg,
        rgba(151, 93, 224, 0.9),
        rgba(21, 228, 216, 0.9)
    );
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 0.85rem;
    letter-spacing: 1px;
`;

export const DashedLine = styled.div`
    font-size: 2rem;
    margin-top: -0.4rem;
    margin-bottom: -1.5rem;
    font-weight: 100;
    height: 2.5rem;
`;

export const BookingButton = styled.div`
    padding: 0.5rem;
    width: 47.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #535353;
`;

export const PromoContent = styled.div`
    font-weight: 700;
    min-height: 1.5rem;
    max-width: 90%;
    text-align: center;
`;

export const BenefitValueRight = styled.div`
    text-align: right;
`;

export const CheckInButton = styled(Button)`
    width: 100%;
`;

export const CheckInCTARow = styled(Row)`
    width: 100%;
    margin: 1.5rem 0;
    color: red;
    font-size: 1rem;
    letter-spacing: 1px;
    justify-content: center;
    text-align: center;
`;

export const StyledProductPreviewHeader = styled.div`
    padding: 2rem;
    display: grid;
    grid-template-columns: auto auto;
`;

export const StyledPreviewHeaderGrid = styled.div<{
    isRightAligned?: boolean;
    color?: string;
    size?: string;
    family?: string;
    weight?: string;
}>`
    align-self: center;

    ${(props) => (props.isRightAligned ? `text-align: right` : null)};
    ${(props) => (props.color ? `color: ${props.color}` : null)};
    ${(props) => (props.size ? `font-size: ${props.size}` : null)};
    ${(props) => (props.weight ? `font-weight: ${props.weight}` : null)};

    ${(props) =>
        props.family
            ? `font-family: ${props.family}`
            : "font-family: Montserrat"};
`;

export const StyledProductTabContainer = styled.div<{ family?: string }>`
    display: flex;
    justify-content: space-around;

    ${(props) =>
        props.family
            ? `font-family: ${props.family}`
            : "font-family: Montserrat"};
`;

export const StyledProductTab = styled.div<{ selected?: boolean }>`
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    padding-bottom: 0.5rem;

    ${(props) => (props.selected ? `color: #15E4D8` : `color: #B4B4B4`)};
    ${(props) => props.selected && `border-bottom: 5px solid #786a6a`};
`;

export const StyledProductTabContent = styled.div<{ isExpanded?: boolean }>`
    padding: 1rem 2rem;
    position: relative;
    min-height: 100px;

    ${(props) =>
        props.isExpanded
            ? null
            : `
            height: 100px;
            overflow: hidden;

            ::before {
                margin-left: -2rem;
                content: "";
                position: absolute;
                width: 100%;
                background: linear-gradient(
                    0deg,
                    #313131,
                    rgba(44, 44, 44) 35%,
                    rgba(0, 0, 0, 0) 100%
                );
                height: calc( 100x - 1rem );
            }`};
`;

export const StyledProductTabExpandButton = styled.button`
    margin-top: 2rem;

    width: 8rem;
    border: 1px solid #15e4d8;
    box-shadow: 0px 4px 8px rgba(12, 64, 148, 0.15);
    color: #15e4d8;
    margin-left: 2rem;
    font-size: 0.75rem;
    padding: 0.25rem 0.25rem;
    background-color: #313131;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
        padding: 0 0.25rem 0 0.5rem;
    }
`;

export const PhotoSliderProgressBar = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
`;

export const PhotoSliderProgressButton = styled.div`
    display: flex;
    align-items: center;

    img.selected {
        width: 2rem;
    }

    img {
        width: 0.5rem;
        margin: 0.5rem;
        cursor: pointer;
    }
`;

export const PreviewHeaderTitle = styled.div`
    font-size: 1.25rem;
    font-weight: 400;
    width: 100%;
    text-align: center;
    padding: 1rem;
`;

export const BookingDetailPreviewContainer = styled.div`
    padding: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const BookingDetailBorderContainer = styled.div`
    margin: 1rem 0.75rem 2rem 0;

    background-image: linear-gradient(
        45deg,
        rgba(151, 93, 224, 0.9),
        rgba(21, 228, 216, 0.9)
    );
    width: 100%;
    padding: 2px;
`;

export const BookingDetailContainer = styled.div`
    width: 100%;
    background-color: rgba(49, 49, 49, 10);
    padding: 0.5rem;

    div {
        display: flex;
        justify-content: space-between;
        padding: 0.125rem 0;
    }
`;

export const ProductTitle = styled.div`
    font-size: 0.75rem;
    color: #15e4d8;
    font-weight: 600;
`;

export const BookingDetailTitle = styled.div`
    font-weight: 800;
    font-size: 1rem;
    padding-bottom: 1.5rem;
`;

export const AdditionalNoteSection = styled.div`
    padding-top: 1rem;
    font-style: italic;
    font-weight: 600;
`;
