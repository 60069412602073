import styled, { layout, LayoutProps } from "@doar/shared/styled";

export const StyledMap = styled(({ width, height, ...props }) => (
    <div {...props} />
))<LayoutProps>`
    ${layout}
`;

export const StyledGoogleMapContainer = styled.div`
    .cluster > div {
        transform: translate(0, -2px);
        font-size: 1rem !important;
    }
`;
