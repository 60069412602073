import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown, LogOut } from "react-feather";
import { Heading, Nav } from "@doar/components";
import { getCookie, removeCookie } from "typescript-cookie";
import { COOKIE } from "src/api";

import {
    StyledLoggedInUser,
    StyledUserBtn,
    StyledUserCollapse,
    StyledUserData,
} from "./style";

const LoggedinUser: React.FC = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const username = getCookie(COOKIE.Username);

    const handleLogout = () => {
        removeCookie(COOKIE.AccessToken, { path: "/" });
        removeCookie(COOKIE.RefreshToken, { path: "/" });
        removeCookie(COOKIE.Username, { path: "/" });
        removeCookie(COOKIE.AdminRole, { path: "/" });

        navigate("/signin");
    };

    return (
        <StyledLoggedInUser className="aside-loggedin-user">
            <StyledUserData className="aside-loggedin-user-data">
                <StyledUserBtn
                    type="button"
                    onClick={() => setShow((prev) => !prev)}
                >
                    <Heading fontWeight={600} mb="0px">
                        {username ?? "Admin"}
                    </Heading>
                    <ChevronDown size={16} strokeWidth="2.3px" />
                </StyledUserBtn>
                {/* <Text color="text3" fontSize="12px">
                    Administrator
                </Text> */}
            </StyledUserData>

            <StyledUserCollapse
                className="aside-loggedin-user-nav"
                $show={show}
            >
                <Nav vertical customStyle="aside">
                    <button
                        type="button"
                        style={{
                            background: "none",
                            border: "none",
                            display: "flex",
                        }}
                        onClick={() => handleLogout()}
                    >
                        <LogOut /> <span>Sign Out</span>
                    </button>
                </Nav>
            </StyledUserCollapse>
        </StyledLoggedInUser>
    );
};

export default LoggedinUser;
