import styled from "@doar/shared";
import React from "react";
import {
    Heart,
    Bell,
    ChevronDown,
    ChevronRight,
    TrendingUp,
    User,
} from "react-feather";
import {
    LocationPreviewHeader,
    StyledPreviewContainer,
    StyledPreview,
} from "src/components/preview/styles";

import LogoWhite from "../../../../shared/images/logo/logo-white.svg";
import Festival from "../../../../shared/images/festival.png";
import Experience from "../../../../shared/images/experience.png";
import Activity from "../../../../shared/images/activity.png";
import Party from "../../../../shared/images/party.png";

interface IProps {
    title: string;
    copy: string;
    buttonName: string;
    imageUrl: string;
}

export const AppNavbar: React.FC = () => {
    return (
        <LocationPreviewHeader
            style={{
                justifyContent: "space-between",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
            }}
        >
            <div style={{ justifyContent: "center" }}>
                <img
                    src={LogoWhite}
                    alt="stoke app logo"
                    width="75px"
                    style={{ color: "#FFFFFF" }}
                />
            </div>
            <div>
                <Heart
                    style={{
                        marginLeft: "1rem",
                        color: "#fff",
                    }}
                />
                <Bell
                    style={{
                        marginLeft: "0.5rem",
                        color: "#fff",
                    }}
                />
            </div>
        </LocationPreviewHeader>
    );
};

// TODO: collate all preview related common styles to one file
const AppHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem 1rem 1.5rem;
`;

const AppHeaderMainText = styled.div`
    font-weight: 700;
    font-family: Montserrat;
    color: #ffffff;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    height: 2rem;
`;

const AppHeaderHightlightMainText = styled.div`
    color: #15e4d8;
    text-decoration: underline;
    padding-left: 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
`;

const AppHeaderCategoryContainer = styled.div`
    display: flex;
    gap: 6%;
    margin: 1.5rem 0;
    align-items: center;
`;

const AppHeaderCategory = styled.div`
    display: flex;
    flex-direction: column;

    div {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 0.875rem;
        text-align: center;
        margin-top: 1rem;
    }
`;

const AppHeaderTabContainer = styled.div`
    display: flex;
`;

const AppHeaderTab = styled.div`
    width: 50%;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 0.875rem;
    display: flex;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;

    border-top: 0;
    border-left: 0;
    border-right: 0;

    border-image-source: linear-gradient(
        90deg,
        #7000ff 0%,
        #975de0 0.01%,
        #15e4d8 99.95%
    );

    svg {
        margin: 0 1rem;
    }
`;

const AppHeaderTabDisabled = styled.div`
    width: 50%;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 0.875rem;
    display: flex;
    border-bottom: 3px solid rgba(255, 255, 255, 0.25);

    svg {
        margin: 0 1rem;
    }
`;

// TODO: pull this out and use it other previews
export const AppHeader: React.FC = () => {
    return (
        <AppHeaderContainer>
            <AppHeaderMainText>
                <div style={{ height: "100%" }}>Cool stuff from{"  "}</div>
                <AppHeaderHightlightMainText>
                    Everywhere
                    <ChevronDown />
                </AppHeaderHightlightMainText>
            </AppHeaderMainText>
            <AppHeaderCategoryContainer>
                <AppHeaderCategory>
                    <img
                        height={66}
                        width={66}
                        src={Festival}
                        alt="festival category blob"
                    />
                    <div>Festivals</div>
                </AppHeaderCategory>
                <AppHeaderCategory>
                    <img
                        height={66}
                        width={66}
                        src={Experience}
                        alt="experiences category blob"
                    />
                    <div style={{ opacity: "50%" }}>Experiences</div>
                </AppHeaderCategory>

                <AppHeaderCategory>
                    <img
                        height={66}
                        width={66}
                        src={Activity}
                        alt="activity category blob"
                    />
                    <div>Activities</div>
                </AppHeaderCategory>

                <AppHeaderCategory>
                    <img
                        height={66}
                        width={66}
                        src={Party}
                        alt="parties category blob"
                    />
                    <div style={{ opacity: "50%" }}>Parties</div>
                </AppHeaderCategory>
            </AppHeaderCategoryContainer>
            <AppHeaderTabContainer>
                <AppHeaderTab>
                    <User height={18} width={18} />
                    For You
                </AppHeaderTab>
                <AppHeaderTabDisabled>
                    <TrendingUp height={18} width={18} opacity={0.25} />
                    <div style={{ opacity: "25%" }}>Trending</div>
                </AppHeaderTabDisabled>
            </AppHeaderTabContainer>
        </AppHeaderContainer>
    );
};

export const ForYouBodyContainer = styled.div`
    display: grid;
    gap: 15px;
    grid-template-columns: 50fr 50fr;
    margin: 0 1.5rem 1rem 1.5rem;
`;

const EmptyGridContent = styled.div`
    height: 13.8rem;
    background-color: #9d9092; ;
`;

const PromoContainer = styled.div`
    height: 13.5rem;
    background-color: #686cbc;
    background-size: cover;
    display: flex;
    width: 100%;
`;

const PromoContent = styled.div`
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 69.79%);
    padding: 0 1rem 1rem 0.5rem;
    overflow: hidden;
    margin-top: 60%;
`;

const PromoTitle = styled.div`
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 0.875rem;
    min-height: 1.5rem;
`;

const PromoCopy = styled.div`
    margin-top: 0.25rem;
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.75rem;
    overflow: hidden;
`;

const PromoButton = styled.span`
    color: #15e4d8;

    svg {
        vertical-align: middle;
    }
`;

const PromoContainerWrapper = styled.div`
    padding: 0.15rem;
    position: relative;
    background: linear-gradient(to right, #7000ff, #15e4d8);
`;

export const renderTitle = (q: string) => {
    const Re = /{{(.+?)}}/g;
    const promoCopyString = q.split(Re);

    const highlightWords = q.match(Re);
    return promoCopyString.map((qs: string) => {
        if (highlightWords?.includes(`{{${qs}}}`)) {
            return (
                <span style={{ color: "#15E4D8" }} key={qs}>
                    {qs}
                </span>
            );
        }
        return <span key={qs}>{qs}</span>;
    });
};

const PromotionPreview: React.FC<IProps> = ({
    title,
    copy,
    buttonName,
    imageUrl,
}) => {
    return (
        <StyledPreviewContainer>
            <StyledPreview>
                <AppNavbar />
                <AppHeader />
                <ForYouBodyContainer>
                    <PromoContainerWrapper>
                        <PromoContainer
                            style={{ backgroundImage: `url(${imageUrl})` }}
                        >
                            <PromoContent>
                                <PromoTitle> {renderTitle(title)}</PromoTitle>
                                <PromoCopy>
                                    {copy}{" "}
                                    <PromoButton>
                                        {" "}
                                        {buttonName}
                                        {buttonName && (
                                            <ChevronRight size={14} />
                                        )}
                                    </PromoButton>
                                </PromoCopy>
                            </PromoContent>
                        </PromoContainer>
                    </PromoContainerWrapper>

                    <EmptyGridContent />
                    <EmptyGridContent />
                    <EmptyGridContent />
                </ForYouBodyContainer>

                <div />
            </StyledPreview>
        </StyledPreviewContainer>
    );
};

export default PromotionPreview;
