import styled, { themeGet } from "@doar/shared/styled";
import {
    ErrorMessage,
    Field,
    FieldHookConfig,
    FieldProps,
    useField,
} from "formik";
import { useEffect } from "react";
import Select, {
    ActionMeta,
    CSSObjectWithLabel,
    MultiValue,
    SingleValue,
} from "react-select";

export const CustomError = styled.div`
    color: red;
    line-height: 1rem;
    margin-top: 0.5rem;
    font-size: 0.75rem;
`;

export const BasicFieldContainer = styled.div<{ hasError?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    textarea {
        min-height: 5rem;
        display: block;
        width: 100%;
        padding: 0.46875rem 0.625rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #1b2e4b;
        border: 1px solid #c0ccda;
        border-radius: 4px;

        ::placeholder {
            color: #c1bfbf;
            opacity: 0.8;
        }

        :disabled {
            background: #f2f2f2;
            border-color: hsl(0, 0%, 90%);
        }
    }

    .custom-input-field {
        ${(props) => props && props.hasError && "border-color: red;"}
    }

    input {
        ::placeholder {
            color: #c1bfbf;
            opacity: 0.8;
        }
    }

    input:disabled {
        background: #f2f2f2;

        ${(props) =>
            props && props.hasError
                ? "border-color: red;"
                : "border-color: hsl(0, 0%, 90%);"}
    }
`;

const BasicField = styled(Field)`
    input {
        ::placeholder {
            color: #c1bfbf;
            opacity: 0.8;
        }
    }

    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1b2e4b;
    border: 1px solid #c0ccda;
    border-radius: 4px;

    ::placeholder {
        color: #c1bfbf;
        opacity: 0.8;
    }
`;

export const BasicFieldLabel = styled.label`
    display: flex;
    font-weight: 400;
    line-height: 1.25rem;

    div {
        font-size: 1.25rem;
        font-weight: 300;
    }
`;

const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding-left: 0.25rem;

    input {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
        margin-left: -0.75rem;
        border: 1px solid ${themeGet("colors.gray500")};
        color: #001737;
        background: transparent;

`;

interface Props {
    name: string;
    label?: string;
    required?: boolean;
}

export default function BasicFieldWithError({
    name,
    label,
    required,
    type,
    ...props
}: FieldHookConfig<string> & Props) {
    const [field, meta] = useField(name);

    const renderField = () => {
        switch (type) {
            case "textarea":
                return (
                    <>
                        <BasicFieldLabel htmlFor={name}>
                            {label} {required && <div>*</div>}
                        </BasicFieldLabel>
                        <Field {...props} name={name} as="textarea" />
                    </>
                );

            case "checkbox":
                return (
                    <CheckboxContainer>
                        <Field type="checkbox" name={name} {...props} />
                        <span>{label}</span>
                    </CheckboxContainer>
                );

            default:
                return (
                    <>
                        <BasicFieldLabel htmlFor={name}>
                            {label} {required && <div>*</div>}
                        </BasicFieldLabel>
                        <FieldContainer>
                            <BasicField
                                className="custom-input-field"
                                {...props}
                                type={type}
                                name={name}
                            />
                        </FieldContainer>
                    </>
                );
        }
    };

    return (
        <BasicFieldContainer hasError={meta.error !== undefined}>
            {renderField()}
            {meta.error && <CustomError>{meta.error}</CustomError>}
        </BasicFieldContainer>
    );
}

export interface SelectOption {
    label: string;
    value: string;
}

const CustomSelectStyle = (hasError: boolean) => {
    return {
        control: (base: CSSObjectWithLabel) => ({
            ...base,
            height: "2.375rem",
            borderColor: hasError ? "red" : "#c0ccda",
        }),
        valueContainer: (base: CSSObjectWithLabel) => ({
            ...base,
            height: "2.375rem",
            padding: "0 0.5rem",
        }),
        input: (base: CSSObjectWithLabel) => ({
            ...base,
            height: "2.375rem",
            margin: "0",
            padding: "0",
            borderColor: "hotpink",
        }),
        placeholder: (base: CSSObjectWithLabel) => ({
            ...base,
            color: "#c1bfbf",
            opacity: "0.8",
        }),
    };
};

// Helper function for converting enums to select options
export const convertToSelectOption = (
    enumInstance: { [x: string]: string },
    // @ts-ignore
    getLabel?: (val: any) => string
) => {
    return Object.keys(enumInstance).map((k) => ({
        value: k,
        label: getLabel
            ? getLabel(k)
            : enumInstance[k as keyof typeof enumInstance].toLowerCase(),
    }));
};

interface BasicSelectWithErrorProps extends FieldProps {
    options: SelectOption[];
    className?: string;
    placeholder?: string;
    defaultValue: string;
    label?: string;
    required?: boolean;
    isMulti?: boolean;
    disabled?: boolean;
    postChange?: (newValue: SelectOption) => void;
}

export function BasicSelectWithError({
    className,
    placeholder,
    field,
    form,
    options,
    defaultValue,
    label,
    required,
    isMulti = false,
    disabled,
    postChange,
}: BasicSelectWithErrorProps) {
    const onChange = (
        newValue:
            | MultiValue<string | SelectOption>
            | SingleValue<string | SelectOption>,
        actionMeta: ActionMeta<string | SelectOption>
    ) => {
        form.setFieldValue(field.name, (newValue as SelectOption).value);

        // Update dependent field
        if (postChange) {
            postChange(newValue as SelectOption);
        }
    };

    const getValue = () => {
        if (options && options.length > 0) {
            const selected = options.find(
                (option) => option.value === field.value
            );

            return selected;
        }
        return isMulti ? [] : "";
    };

    // Clear the value if it doesn't match select
    useEffect(() => {
        if (getValue() === undefined) {
            form.setFieldValue(field.name, null);
        }
    }, [options]);

    const hasError = Object.keys(form.errors).includes(field.name);

    return (
        <BasicFieldContainer>
            <BasicFieldLabel htmlFor={field.name}>
                {label} {required && <div>*</div>}
            </BasicFieldLabel>
            <Select
                placeholder={placeholder}
                defaultValue={defaultValue}
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                styles={CustomSelectStyle(hasError)}
                isDisabled={disabled}
            />
            <ErrorMessage component={CustomError} name={field.name} />
        </BasicFieldContainer>
    );
}
