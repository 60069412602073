import {
    Children,
    FC,
    FunctionComponent,
    ReactChild,
    ReactText,
    useState,
} from "react";
import styled, { LayoutProps } from "@doar/shared/styled";
import {
    GoogleMap as GoogleMapComponent,
    useJsApiLoader,
    Marker as MarkerComponent,
    MarkerClusterer,
    Autocomplete,
} from "@react-google-maps/api";
import { StyledGoogleMapContainer, StyledMap } from "./style";
import { css } from "@doar/shared";
import M1 from "../../../../shared/images/cluster/m1.svg";

interface IProps extends LayoutProps {
    /**
     * Required. Pass google maps latitude
     */
    lat: number;
    /**
     * Required. Pass google maps longitude
     */
    lng: number;
    /**
     * 	The initial Map zoom level. Required. Valid values: Integers between zero, and up to the supported maximum zoom level.
     */
    zoom?: number;
}

type IChild = Exclude<ReactChild, ReactText>;

const libraries: (
    | "places"
    | "drawing"
    | "geometry"
    | "localContext"
    | "visualization"
)[] = ["places"];

const GoogleAPIOptions = {
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDbTNLN_mFBRvxgHk_bJ1AjAi07yQyEDYY",
    libraries: libraries,
};

const autocompleteClassName = css`
    background: red;
`;

export const GoogleMap: FC<IProps> = ({
    width,
    height,
    lat,
    lng,
    zoom,
    children,
}) => {
    // const { isLoaded, loadError } = useJsApiLoader({
    //     id: "google-map-script",
    //     googleMapsApiKey: MapKey,
    // });
    const { isLoaded, loadError } = useJsApiLoader(GoogleAPIOptions);

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    const RenderChild = Children.map(children, (el) => {
        const child = el as IChild;
        if (child !== null) {
            const childType = child.type as FunctionComponent;
            const name = childType.displayName || childType.name;
            if (name === "GoogleMapMarker") {
                return <child.type lat={lat} lng={lng} {...child.props} />;
            }
        }
        return null;
    });

    return isLoaded ? (
        <StyledMap width={width} height={height}>
            <GoogleMapComponent
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={{ lat, lng }}
                zoom={zoom}
            >
                {RenderChild}
            </GoogleMapComponent>
        </StyledMap>
    ) : (
        <div>Map is loading.</div>
    );
};

interface IMarkerProps {
    /**
     * Required. Pass google maps latitude
     */
    lat: number;
    /**
     * Required. Pass google maps longitude
     */
    lng: number;
}

export const GoogleMapMarker: FC<IMarkerProps> = ({ lat, lng }) => {
    return <MarkerComponent position={{ lat, lng }} />;
};

GoogleMap.defaultProps = {
    width: "100%",
    height: "400px",
    lat: -3.745,
    lng: -38.523,
    zoom: 12,
};

// CUSTOM GOOGLE MAPS COMPONENTwh
// TODO: pull out into our own component after installing this api

type LocationBlob = {
    lat: number;
    lng: number;
    icon: string;
};

interface CustomGoogleMapProps extends LayoutProps {
    lat: number;
    lng: number;
    zoom?: number;
    locations: LocationBlob[];
    disableDrag?: boolean;
}

export const CustomGoogleMap: FC<CustomGoogleMapProps> = ({
    width,
    height,
    lat,
    lng,
    zoom,
    locations,
}) => {
    const { isLoaded, loadError } = useJsApiLoader(GoogleAPIOptions);
    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    // TODO; fix clustering images
    return isLoaded ? (
        <StyledGoogleMapContainer>
            <StyledMap width={width} height={height}>
                <GoogleMapComponent
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    center={{ lat, lng }}
                    zoom={zoom}
                >
                    <MarkerClusterer
                        minimumClusterSize={4}
                        styles={[
                            {
                                url: M1,
                                height: 39,
                                width: 39,
                            },
                            {
                                url: M1,
                                height: 39,
                                width: 39,
                            },
                            {
                                url: M1,
                                height: 39,
                                width: 39,
                            },
                            {
                                url: M1,
                                height: 39,
                                width: 39,
                            },
                            {
                                url: M1,
                                height: 39,
                                width: 39,
                            },
                        ]}
                    >
                        {(clusterer) =>
                            locations.map((loc, i) => (
                                <MarkerComponent
                                    key={i}
                                    position={loc}
                                    clusterer={clusterer}
                                    icon={loc.icon}
                                />
                            ))
                        }
                    </MarkerClusterer>
                </GoogleMapComponent>
            </StyledMap>
        </StyledGoogleMapContainer>
    ) : (
        <div>Map is loading.</div>
    );
};

CustomGoogleMap.defaultProps = {
    width: "100%",
    height: "400px",
    lat: -3.745,
    lng: -38.523,
    zoom: 12,
};

const mapContainerStyle = {
    height: "400px",
    width: "100%",
};

const center = {
    lat: 38.685,
    lng: -115.234,
};

interface CustomMapWithAutoCompleteProps extends LayoutProps {
    handleAddressChange: (location: {
        markerAddress: string;
        markerLat: number;
        markerLng: number;
        markerCity: string;
        markerCountry: string;
    }) => void;
    initialAddress?: string;
    initialMarkerLocation?: { lat: number; lng: number };
    isValid: boolean;
    disabled?: boolean;
}

const StyledAutocomplete = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 325px;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .autocomplete-container {
        display: flex;
        padding-top: 1rem;
        align-items: center;
    }
`;

const ErrorMessage = styled.div`
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`;

type GoogleAddressComponentType =
    | "locality"
    | "postal_town"
    | "administrative_area_level_2"
    | "country";

interface GoogleAddressComponents {
    long_name: string;
    short_name: string;
    types: GoogleAddressComponentType[];
}

const getCityAndCountry = (addressComp: GoogleAddressComponents[]) => {
    const city = addressComp.find(
        (a) => a.types.includes("postal_town") || a.types.includes("locality")
    )?.long_name;
    const country = addressComp.find((a) => a.types.includes("country"))
        ?.long_name;

    return { city, country };
};

// Used for location create/ edit
export const CustomMapWithAutoComplete: FC<CustomMapWithAutoCompleteProps> = ({
    handleAddressChange,
    initialAddress,
    initialMarkerLocation,
    isValid,
    disabled,
}) => {
    const { isLoaded, loadError } = useJsApiLoader(GoogleAPIOptions);

    const [searchBox, setSearchBox] = useState<Autocomplete | null>(null);
    const [searchedPlaceLocation, setSearchPlaceLocation] = useState(null);
    const [initialPlace, setInitialPlace] = useState<string>("");
    const [initialMarker, setInitialMarker] = useState(null);

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    if (isLoaded && initialAddress && !initialPlace) {
        setInitialPlace(initialAddress);
        //@ts-ignore
        setInitialMarker(initialMarkerLocation);
    }
    const onLoad = (ref: Autocomplete) => setSearchBox(ref);

    const onPlaceChanged = () => {
        if (searchBox) {
            // @ts-ignore
            const p = searchBox.getPlace();

            // Case where place has been cleared
            if (!p) {
                return;
            }

            if (!p.geometry || !p.geometry.location) {
                window.alert(
                    "No details available for input: '" + p.name + "'"
                );
                return;
            }

            if (p.geometry.location) {
                const { city, country } = getCityAndCountry(
                    p.address_components
                );
                setSearchPlaceLocation(p.geometry.location);
                handleAddressChange({
                    markerAddress: p.formatted_address,
                    markerLat: p.geometry.location.lat(),
                    markerLng: p.geometry.location.lng(),
                    markerCity: city ?? "",
                    markerCountry: country ?? "",
                });
            }
        }
    };

    const getMapCenter = () => {
        return searchedPlaceLocation
            ? searchedPlaceLocation
            : initialMarker
            ? initialMarker
            : center;
    };

    const calculateZoom = () => {
        return !initialMarker && !searchedPlaceLocation ? 2.5 : 14;
    };

    return isLoaded ? (
        <StyledAutocomplete>
            <GoogleMapComponent
                id="searchbox-example"
                mapContainerStyle={mapContainerStyle}
                zoom={calculateZoom()}
                center={getMapCenter()}
                options={{ draggable: false }}
            >
                {initialMarker && !searchedPlaceLocation ? (
                    <MarkerComponent position={initialMarker} />
                ) : (
                    <MarkerComponent position={searchedPlaceLocation} />
                )}
            </GoogleMapComponent>
            <Autocomplete
                className="autocomplete-container"
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
            >
                <>
                    <input
                        type="text"
                        placeholder="Enter a location"
                        style={{
                            display: "block",
                            width: "100%",
                            height: "38px",
                            padding: " 0.46875rem 0.625rem",
                            fontSize: "0.875rem",
                            lineHeight: "1.5",
                            color: "#1b2e4b",
                            backgroundColor: disabled ? "#f5f6fa" : " #fff",
                            border: " 1px solid #c0ccda",
                            borderRadius: " 4px",
                            borderColor: isValid ? "#c0ccda" : "#dc3545",
                        }}
                        defaultValue={initialPlace}
                        disabled={disabled}
                    />
                    {/* <X
                        style={{
                            marginLeft: "0.5rem",
                            height: "1rem",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setSearchPlaceLocation(null);
                            //@ts-ignore
                            searchBox?.set("place", null);
                        }}
                    /> */}
                </>
            </Autocomplete>
            {!isValid && <ErrorMessage>You must enter an address</ErrorMessage>}
        </StyledAutocomplete>
    ) : (
        <div>Map is loading.</div>
    );
};
