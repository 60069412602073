import { Table } from "@doar/components";
import { FC } from "react";
import { MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb";
import {
    StyledWelcomeArea,
    StyledWelcomeLeft,
    StyledWelcomeRight,
    StyledButton,
} from "./style";

export const WelcomeAreaDashboard: FC = () => {
    return (
        <StyledWelcomeArea>
            <StyledWelcomeLeft>
                <Breadcrumb
                    prev={[{ text: "Stoke Admin Panel", link: "/" }]}
                    title="Promotions"
                    wcText="Promotional Contents"
                />
            </StyledWelcomeLeft>
            <StyledWelcomeRight>
                <StyledButton size="sm" hasIcon mt="10px">
                    <Link to="/promotions/new">+ Create New Promotion</Link>
                </StyledButton>
            </StyledWelcomeRight>
        </StyledWelcomeArea>
    );
};

export const WelcomeAreaCreateForm: FC = () => {
    return (
        <StyledWelcomeArea>
            <StyledWelcomeLeft>
                <Breadcrumb
                    prev={[{ text: "Promotions", link: "/promotions" }]}
                    title="Create new Promotion"
                    wcText="Create new Promotion"
                />
            </StyledWelcomeLeft>
        </StyledWelcomeArea>
    );
};

type EditPromoProps = {
    promotionName: string;
};

export const WelcomeAreaEditForm: FC<EditPromoProps> = (props) => {
    const { promotionName } = props;
    return (
        <StyledWelcomeArea>
            <StyledWelcomeLeft>
                <Breadcrumb
                    prev={[{ text: "Promotions", link: "/promotions" }]}
                    title={promotionName}
                    wcText="Edit Promotion"
                />
            </StyledWelcomeLeft>
        </StyledWelcomeArea>
    );
};

export const WelcomeAreaPlacementForm: FC = () => {
    return (
        <StyledWelcomeArea>
            <StyledWelcomeLeft>
                <Breadcrumb
                    prev={[
                        {
                            text: "Pinned Promotions",
                            link: "/promotions/pinned",
                        },
                    ]}
                    title="Manage promotion placement"
                    wcText="Manage promotion placement"
                />
            </StyledWelcomeLeft>
        </StyledWelcomeArea>
    );
};

export const WelcomeAreaPinnedDashboard: FC = () => {
    return (
        <StyledWelcomeArea>
            <StyledWelcomeLeft>
                <Breadcrumb
                    prev={[{ text: "Promotions", link: "/promotions" }]}
                    title="Pinned Promotions"
                    wcText="Pinned Promotions"
                />
            </StyledWelcomeLeft>
        </StyledWelcomeArea>
    );
};
