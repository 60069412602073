import { Label } from "@doar/components";
import styled from "@doar/shared";
import { Formik } from "formik";
import { useState } from "react";
import PromotionPlacementPreview from "src/components/preview/promotion-placement-preview";
import { WelcomeAreaPlacementForm } from "src/containers/promotion/welcome-area";
import Layout from "src/layouts/layout-02";
import Content from "src/layouts/layout-02/content";
import ContentBody from "src/layouts/layout-02/content-body";
import { StyledCol, StyledForm, StyledRow } from "../location/forms/styles";
import BasicFieldWithError from "../shared/form/BasicFieldWithError";

interface PromoPlacementFormValue {
    startIndex: number | null;
    repeatPosition: number | null;
}

const DUMMY_PROMO_PLACEMENT_RULE: PromoPlacementFormValue = {
    startIndex: null,
    repeatPosition: null,
};

const PromoBoldText = styled.div`
    font-weight: 600;
    padding: 0 0.25rem;
`;

const PromoPlacementForm: React.FC = () => {
    const [initialValue, setInitialValue] = useState<PromoPlacementFormValue>(
        DUMMY_PROMO_PLACEMENT_RULE
    );
    const fetchPromoPlacementRule = () => {
        // TODO: fetch promo placement rule from backend
    };

    const onSubmit = (values: PromoPlacementFormValue) => {
        console.log(values);
        // TODO: submit form to backend
    };

    const formatTilePosition = (tile: number | null) => {
        if (tile !== null) {
            switch (tile) {
                case 1:
                    return "every";
                case 2:
                    return "every other";
                case 3:
                    return "every 3rd";
                default:
                    return `every ${tile}th`;
            }
        }
        return "every Y";
    };

    return (
        <>
            <Layout>
                <Content>
                    <ContentBody>
                        <WelcomeAreaPlacementForm />
                        <Formik
                            initialValues={initialValue}
                            onSubmit={(values, actions) => onSubmit(values)}
                        >
                            {(props) => (
                                <StyledForm>
                                    <StyledCol>
                                        <StyledRow>
                                            <BasicFieldWithError
                                                type="number"
                                                name="startIndex"
                                                id="startIndex"
                                                label="Placement of the first promo"
                                                placeholder="Enter placement of the first promo"
                                                max={10}
                                                min={1}
                                            />
                                        </StyledRow>

                                        <StyledRow>
                                            <BasicFieldWithError
                                                type="number"
                                                name="repeatPosition"
                                                id="repeatPosition"
                                                label="Promo interval"
                                                placeholder="Enter promo interval"
                                                max={10}
                                                min={1}
                                            />
                                        </StyledRow>
                                        <StyledRow>
                                            {/* TODO: change text to be more coherant */}
                                            <Label htmlFor="rule">
                                                Current Promo placement rule
                                            </Label>
                                            Place first promo on tile{" "}
                                            <PromoBoldText>
                                                {props.values.startIndex ?? "X"}
                                            </PromoBoldText>
                                            and then on
                                            <PromoBoldText>
                                                {formatTilePosition(
                                                    props.values.repeatPosition
                                                )}
                                            </PromoBoldText>
                                            tile(s) afterwards
                                        </StyledRow>
                                    </StyledCol>
                                    <StyledCol>
                                        <PromotionPlacementPreview
                                            startIndex={props.values.startIndex}
                                            repeatPosition={
                                                props.values.repeatPosition
                                            }
                                        />
                                    </StyledCol>
                                </StyledForm>
                            )}
                        </Formik>
                    </ContentBody>
                </Content>
            </Layout>
        </>
    );
};

export default PromoPlacementForm;
